import { Box } from '@mui/system'
import React, { PureComponent } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export class Loader extends PureComponent {
  render() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh'
        }}
      >
        <CircularProgress disableShrink />
      </Box>
    )
  }
}

export default Loader
