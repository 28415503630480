import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals'
import './Fonts/fonts.css'
import './index.css'

const renderReactDom = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

// Configuring react dom to be loaded after cordova’s ondevice ready event:
if (window.cordova) {
  document.addEventListener('deviceready', () => {
    window.screen.orientation.lock('portrait-primary')
    navigator.geolocation.getCurrentPosition((position) => {
      window.position = position
    })
    window.pushNotification.registration((token) => {
      console.log(token);
    })
    renderReactDom()
  }, false)
} else {
  renderReactDom()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
