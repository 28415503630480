import { createTheme } from '@mui/material'


const darkThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#fff',
      paper: '#fafafa'
    },
    primary: {
      main: '#ed2326',
    },
    secondary: {
      main: '#e9e614',
    },
    error: {
      main: '#ff5938',
    },
    warning: {
      main: '#ffdc00',
    },
    info: {
      main: '#00b6ff',
    },
    success: {
      main: '#53d836',
    },
    divider: 'rgba(255,255,255,0.5)',
  },
  components: {
    MuiAppBar: {
      // defaultProps: {
      //   color: 'secondary'
      // }
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FDE1E0",
        }
      }
    }
  }
}

const darkTheme = createTheme(darkThemeOptions)

export {
  darkTheme
}
