import React, { PureComponent, Suspense } from 'react'
import {
  Routes,
  Route
} from 'react-router-dom'
import Loader from '../SharedComponents/Loader'
import {
  ROUTE_ABOUT,
  ROUTE_CATEGORIES,
  ROUTE_HOME,
  ROUTE_SERVICES,
  ROUTE_PRODUCT_LIST,
  ROUTE_PRODUCT_DETAILS,
  ROUTE_BANK_DETAILS
} from './AppRoutes'

const LayoutComponent = React.lazy(() => import('../SharedComponents/Layout'))

const HomeComponent = React.lazy(() => import('../Pages/Home'))
const AboutComponent = React.lazy(() => import('../Pages/About'))
const ServicesComponent = React.lazy(() => import('../Pages/Services'))
const CategoriesComponent = React.lazy(() => import('../Pages/Categories'))
const ProductListComponent = React.lazy(() => import('../Pages/ProductList'))
const ProductDetailsComponent = React.lazy(() => import('../Pages/ProductDetails'))
// const BankDetailsComponent = React.lazy(() => import('../Pages/BankDetails'))

class AppRouter extends PureComponent {
  render () {
    return (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<LayoutComponent />}>
            <Route index path={ROUTE_HOME} element={<HomeComponent />} />
            <Route path={ROUTE_ABOUT} element={<AboutComponent />} />
            <Route path={ROUTE_SERVICES} element={<ServicesComponent />} />
            <Route path={ROUTE_CATEGORIES} element={<CategoriesComponent />} />
            <Route path={ROUTE_PRODUCT_LIST} element={<ProductListComponent />} />
            <Route path={ROUTE_PRODUCT_DETAILS} element={<ProductDetailsComponent />} />
            {/* <Route path={ROUTE_BANK_DETAILS} element={<BankDetailsComponent />} /> */}
          </Route>
        </Routes>
      </Suspense>
    )
  }
}

export default AppRouter
