import { HashRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import AppRouter from './Configurations/AppRouter'
import { darkTheme } from './Configurations/AppTheme'

function App () {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme />
      <HashRouter>
        <AppRouter />
      </HashRouter>
    </ThemeProvider>
  )
}

export default App
